.images{
  margin-top: 20px;
  margin-bottom: -55px;
  border-style: solid;
  border-color: lawngreen;
  padding: 10%;
}

.blocosnumber{
  text-align: start;
  align-content: space-around;
  padding: 10px;
}

.row{
  justify-content: space-between;
}

.blocselectplusbutton{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -13px;
  margin-left: 7px;

}

.cards {
	max-width: 1200px;
	margin: 0 auto;
	display: grid;
	grid-gap: 1rem;
  }