.title{
	margin-top: 50px;
	padding-left: 25px;
	padding-right: 25px;
}

.projects{
	border-style: solid;
	border-color: orangered;
}

.slabs{
	border-style: solid;
	border-color: orchid;
	width: 600px;
}

.column {
    float: left;
    width: 50%;
    padding: 5px;
    height: 80%;
  }

  .row::after {
    content: "";
    clear: both;
    display: table;
  }


  .slabshome{
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
