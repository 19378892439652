
@import url('https://stackpath.bootstrapcdn.com/bootswatch/4.3.1/cerulean/bootstrap.min.css');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');

:root {
    --first-color: rgba(0, 0, 10, 0.6);
    --second-color: rgb(31, 30, 38);
    --third-color: #B0BEC5;
    --fourth-color: #878787;
  }
  
* {
margin: 0;
box-sizing: border-box;
}

body {
background: var(--second-color);
color: var(--fourth-color);
}

h1, h2, h3, h4, h5 {
color: var(--fourth-color);
}

.Button {
border: none;
border-radius: 2px;
background: #53565b;
color: #c8cace;
padding: 8px;
outline: none;
text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}


.MainView {
display: flex;
justify-content: space-between;


}

.inputs {
font-size: 14px;
width: 290px;
min-width: 280px;
padding: 20px;
background: var(--first-color);
color: white;
}

.inputs form {
margin: 30px 0;
}

input {
margin: 5px;
color: #006064;
}

input[type=range] {
outline: none;
background: red;
color: red;
}

input[type=range]:hover {
cursor: pointer;
}

input[type=number] {
font-size: 1.4rem;
width: 60px;
margin: 0 10px;
border: 0;
text-align: right;
background: 0;
color: #B0BEC5;
}


button:hover {
cursor: pointer;
}

button.active {
  background: limegreen;
  font-weight: bold
}
/* button. */



.annotation {
height: 200px;
display: flex;
flex-direction: column;
justify-content: flex-end;
font-size: 80%;
}

.FilterBar {
  width: 200px;
  background: var(--first-color);
  padding: 0 10px;
}


.FilterImage {
  margin: 14px 5px;
}

.FilterImage__img {
  height: 110px;
  width: 150px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
  margin: 0 10px;
}

.FilterImage p {
  margin: 0;
  text-align: center;
}

.LayerBar {
  width: 150px;
/*   background: var(--first-color); */
  padding: 0 10px;
}


.layerImage {
  margin: 14px 5px;
}

.layerImage__img {
  height: 110px;
  width: 150px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
  margin: 0 10px;
}

.layerImage p {
  margin: 0;
  text-align: center;
}

.Edit{
  margin-top: 50px;
}



#wrapper {
  position: relative;
  float:left;
  left:60px;
  width: 1000px;
  top: 10px;

}

#canvas{
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;

}
#ruler{
  position: absolute;
  z-index: 1;
  left: -60px;
  top: -20px;
} 

#top{
  position: absolute;
  z-index: 2;
  left: 0px;
  top: 0px;
} 

.MainCanvasView{
  position: relative;

}
.button-group{
  display: inline;
  float: left;
  left: 0%;
  border: 0px;
  margin: 0px;
  padding: 20px;

}

.divider{
  width:5px;
  height:auto;
  display:inline-block;
}

#measureText{
  position:absolute;
  border:1px solid blue; 
 
}

.notPossible{
  color:#ff0000
}