.home {
  margin: 45px;
  height: 80%;
}

.column {
  float: left;
  width: 50%;
  padding: 5px;
  height: 80%;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

.slabshome {
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.frontwaveImg {
  width: 140px;
  height: 110px;
  background-image: url("./img/frontwave.png");
  background-repeat: no-repeat;
  background-size: 100%;
}

.stonecitiImg {
  width: 150px;
  height: 150px;
  background-image: url("./img/logo_wescan_orange.png");
  background-repeat: no-repeat;
  background-size: 100%;
}

.frontwaveImgHome {
  width: 165px;
  height: 125px;
  background-image: url("./img/frontwave.png");
  background-repeat: no-repeat;
  background-size: 100%;
}

.stonecitiImgHome {
  width: 180px;
  height: 180px;
  background-image: url("./img/logo_wescan_orange.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
