.card img {
	height: 300px;

}

.filters{
	margin-top: 60px;
	padding-left: 1px;
	padding-right: 25px;
}

.datepicker{
	margin-top: -16px;
}

.btncatalog{
	width: 180px;
	height: 50px;
}

/* display: flex; justify-content: flex-end */
.btncattalogdiv{
	margin-top: -5px;
	margin-left: 50px;
	display: flex;
	justify-self: flex-end;	
}

.filter{
	align-content: space-between;
	/* display: flex;
	justify-content: center; */
}

.selectpicker{
	display: flex;
	justify-content: center;
	margin-left: 8px;
}

.doneicon{
	/* margin-top: 25%; */
	position: absolute;
	opacity: 0.7;	
}

.cards {
	max-width: 1200px;
	margin: 0 auto;
	display: grid;
	grid-gap: 1rem;
  }
  
  /* Screen larger than 600px? 2 column */
  @media (min-width: 600px) {
	.cards { grid-template-columns: repeat(2, 1fr); }
  }
  
  /* Screen larger than 900px? 3 columns */
  @media (min-width: 900px) {
	.cards { grid-template-columns: repeat(3, 1fr); }
  }  