.dialog {
  box-shadow: 0 8px 6px -6px black;
  position: static;
  left: 20%;
  top: 10%;
}

.image {
  width: 90%;
}

.text { 
  position: absolute;  /* needs to have a higher z-index than the pseduo elements so text  appears on top */
  z-index: 2;
  bottom: 0px;
}